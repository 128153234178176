import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import mq, { mqdown } from '@mq';
import { linkHover } from '@mixins';
import ArrowSvg from '@icons/arrow.svg';

const Filter = ({ filterProps, setFilterProp, currentFilterProp }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = useCallback(() => {
    setExpanded(previous => !previous);
  }, []);

  return (
    <Container>
      <Header onClick={toggleExpand} expanded={expanded}>
        <div>Filtern nach</div>
        <ArrowSvg />
      </Header>
      <FilterProps expanded={expanded}>
        {filterProps.universities.map(university => (
          <FilterProp
            key={university.id}
            active={currentFilterProp.university === university.id}
            onClick={() => {
              if (currentFilterProp.university === university.id) {
                setFilterProp(previous => ({
                  ...previous,
                  university: null,
                }));
              } else {
                setFilterProp(previous => ({
                  ...previous,
                  university: university.id,
                }));
              }
            }}
          >
            {university.shortTitle}
          </FilterProp>
        ))}
      </FilterProps>
      <FilterProps expanded={expanded}>
        {filterProps.locations.map(location => (
          <FilterProp
            key={location.id}
            active={currentFilterProp.location === location.id}
            onClick={() => {
              if (currentFilterProp.location === location.id) {
                setFilterProp(previous => ({
                  ...previous,
                  location: null,
                }));
              } else {
                setFilterProp(previous => ({
                  ...previous,
                  location: location.id,
                }));
              }
            }}
          >
            {location.street}
          </FilterProp>
        ))}
      </FilterProps>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  row-gap: 0.75rem;
  text-transform: uppercase;
  font: var(--font-small);
  margin-bottom: var(--space-double);
  margin-top: calc(-1 * var(--space));
  user-select: none;

  ${mqdown.medium} {
    order: 1;
  }

  ${mq.medium} {
    margin-top: 4.8rem;
    justify-content: start;
    justify-items: start;
  }
`;

const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.75rem;
  cursor: pointer;
  justify-content: start;
  align-items: center;

  ${linkHover};

  ${mq.medium} {
    row-gap: 1rem;
  }

  svg {
    transform: rotate(${props => props.expanded && '180deg'});
  }
`;

const FilterProps = styled.div`
  display: grid;
  column-gap: var(--space);
  grid-auto-flow: column;
  justify-content: start;
  opacity: ${props => (props.expanded ? 1 : 0)};
`;

const FilterProp = styled.div`
  --content-color: ${props => (props.active ? 'var(--color-grey)' : 'var(--color-text)')};

  transition: color var(--motion) ease-in-out;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.75rem;
  justify-content: start;
  align-items: center;
  color: var(--content-color);

  ${mq.medium} {
    row-gap: 1rem;
  }

  ${linkHover};

  svg {
    transition: opacity var(--motion);
    opacity: ${props => (props.active ? 1 : 0)};
    transform: rotate(${props => props.descending && '180deg'});
  }
`;

export default Filter;
