import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import { linkHover, imageHover } from '@mixins';
import mq from '@mq';
import Image from '@core/Image';

const ProjectCard = ({
  image,
  author,
  location: { city, street },
  professor,
  university: { title: university },
  slug,
}) => (
  <Container
    to={slug}
    state={{
      modal: true,
    }}
  >
    <ProjectImage image={image} />
    <Author>
      {author.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </Author>
    <Details>
      <Location>
        {street}, {city}
      </Location>
      <div>{university}</div>
      <div>{professor}</div>
    </Details>

    <Details />
  </Container>
);

const Container = styled(GatsbyLink)`
  position: relative;

  ${linkHover};
`;

const Author = styled.h2`
  font: var(--font-project-card-title);
  margin-bottom: 0.75rem;

  ${mq.medium} {
    margin-bottom: 0.5rem;
  }
`;

const Details = styled.div`
  font: var(--font-project-card);
`;

const Location = styled.div`
  margin-bottom: 0.75em;

  ${mq.medium} {
    margin-bottom: 0.5rem;
  }
`;

const ProjectImage = styled(Image)`
  margin-bottom: 1.5rem;
  ${imageHover};

  ${mq.medium} {
    margin-bottom: var(--space);
  }
`;

export default ProjectCard;
