import React, { useState, useMemo, useCallback } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Navigation from '@core/Navigation';
import Seo from '@core/Seo';
import ProjectCard from '@components/ProjectCard';
import mq from '@mq';
import Sort from '@components/Sort';
import Filter from '@components/Filter';
import useFilter from '@hooks/useFilter';
import { shuffle } from '@utils';

const sortProps = [
  {
    name: 'sortAuthor',
    label: 'Name',
  },
  {
    name: 'sortUniversity',
    label: 'Universität',
  },
  {
    name: 'sortLocation',
    label: 'Standort',
  },
];

const ProjectsPage = ({
  data: {
    allContentfulProject: { nodes: unsortedProjects },
    allContentfulUniversity: { nodes: universities },
    allContentfulLocation: { nodes: locations },
  },
}) => {
  const shuffledAndUnsortedProjects = useMemo(
    () =>
      shuffle(unsortedProjects).map(project => ({
        ...project,
        sortUniversity: project.university.title,
        sortLocation: `${project.location.street}, ${project.location.city}`,
      })),
    [unsortedProjects]
  );

  const filterProps = useMemo(
    () => ({
      universities,
      locations,
    }),
    [universities, locations]
  );

  const {
    sortProp,
    setSortProp,
    filterProp,
    setFilterProp,
    descending,
    toggleDirection,
    items: projects,
  } = useFilter(shuffledAndUnsortedProjects);

  return (
    <>
      <Seo pageTitle="Entwürfe" />
      <Navigation />
      <main>
        <FilterContainer>
          <Filter
            filterProps={filterProps}
            setFilterProp={setFilterProp}
            currentFilterProp={filterProp}
          />
          <Sort
            sortProps={sortProps}
            setSortProp={setSortProp}
            currentSortProp={sortProp}
            descending={descending}
            toggleDirection={toggleDirection}
          />
        </FilterContainer>

        <ProjectList>
          {projects.map(project => (
            <ProjectItem key={project.id} visible={project.visible}>
              <ProjectCard {...project} />
            </ProjectItem>
          ))}
        </ProjectList>
      </main>
    </>
  );
};

const ProjectList = styled.ul`
  display: grid;
  row-gap: 5rem;

  ${mq.medium} {
    grid-template-columns: repeat(4, 1fr);
    column-gap: var(--grid-gap);
  }
`;

const ProjectItem = styled.li`
  display: ${props => (props.visible ? 'grid' : 'none')};
`;

const FilterContainer = styled.div`
  display: grid;

  ${mq.medium} {
    display: flex;
    justify-content: space-between;
  }
`;

export const projectsPageQuery = graphql`
  query projectsPageQuery {
    allContentfulUniversity(sort: { fields: shortTitle }) {
      nodes {
        id
        shortTitle
      }
    }
    allContentfulLocation(sort: { fields: street }) {
      nodes {
        id
        street
      }
    }
    allContentfulProject {
      nodes {
        id
        author
        sortAuthor
        professor
        university {
          id
          title
          shortTitle
        }
        location {
          id
          city
          street
        }
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 325
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP]
            aspectRatio: 1.52
            resizingBehavior: FILL
            cropFocus: FACES
            quality: 80
          )
        }
      }
    }
  }
`;

export default ProjectsPage;
