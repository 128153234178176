import { useState, useMemo, useCallback, useEffect } from 'react';

function useFilter(unsortedItems, initialSort) {
  const [sortProp, setSortProp] = useState(initialSort);
  const [filterProp, setFilterProp] = useState({});
  const [descending, setDescending] = useState(false);

  const items = useMemo(
    () =>
      sortArrayOfObjects(unsortedItems, sortProp, descending).map(project => {
        if (!filterProp.university && !filterProp.location) {
          return {
            ...project,
            visible: true,
          };
        }

        if (!filterProp.university) {
          if (filterProp.location === project.location.id) {
            return {
              ...project,
              visible: true,
            };
          }
        }

        if (!filterProp.location) {
          if (filterProp.university === project.university.id) {
            return {
              ...project,
              visible: true,
            };
          }
        }

        if (
          filterProp.university === project.university.id &&
          filterProp.location === project.location.id
        ) {
          return {
            ...project,
            visible: true,
          };
        }

        return {
          ...project,
          visible: false,
        };
      }),
    [unsortedItems, sortProp, descending, filterProp]
  );

  const toggleDirection = useCallback(({ reset }) => {
    if (reset) {
      setDescending(true);
    } else {
      setDescending(previous => !previous);
    }
  }, []);

  return useMemo(
    () => ({
      items,
      sortProp,
      setSortProp,
      filterProp,
      setFilterProp,
      descending,
      toggleDirection,
    }),
    [items, sortProp, filterProp, toggleDirection, descending]
  );
}

function sortArrayOfObjects(array, property, descending = true) {
  if (!property) {
    return array;
  }

  if (property === 'date') {
    return [...array].sort((current, next) => {
      if (descending) {
        return new Date(current.isodate) - new Date(next.isodate);
      }

      return new Date(next.isodate) - new Date(current.isodate);
    });
  }

  return [...array].sort((current, next) => {
    if (!descending) {
      if (current[property] > next[property]) {
        return -1;
      }

      if (current[property] < next[property]) {
        return 1;
      }

      return 0;
    }

    if (current[property] < next[property]) {
      return -1;
    }

    if (current[property] > next[property]) {
      return 1;
    }

    return 0;
  });
}

export default useFilter;
