import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import mq from '@mq';
import { linkHover } from '@mixins';
import ArrowSvg from '@icons/arrow.svg';

const Sort = ({ sortProps, setSortProp, currentSortProp, descending, toggleDirection }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = useCallback(() => {
    setExpanded(previous => !previous);
  }, []);

  return (
    <Container>
      <Header onClick={toggleExpand} expanded={expanded}>
        <div>Sortieren nach</div>
        <ArrowSvg />
      </Header>
      <SortProps expanded={expanded}>
        {sortProps.map((sortProp, index) => (
          <SortProp
            active={currentSortProp === sortProp.name}
            key={index}
            descending={descending}
            onClick={() => {
              if (currentSortProp !== sortProp.name) {
                toggleDirection({ reset: true });
                setSortProp(sortProp.name);
              } else {
                toggleDirection({ reset: false });
              }
            }}
          >
            {sortProp.label}
            <ArrowSvg />
          </SortProp>
        ))}
      </SortProps>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  row-gap: 0.75rem;
  text-transform: uppercase;
  font: var(--font-small);
  margin-bottom: var(--space-double);
  margin-top: var(--space);
  user-select: none;
  align-content: start;

  ${mq.medium} {
    margin-top: 4.8rem;
    justify-content: end;
    justify-items: end;
  }
`;

const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.75rem;
  cursor: pointer;
  justify-content: start;
  align-items: center;

  ${linkHover};

  ${mq.medium} {
    row-gap: 1rem;
  }

  svg {
    transform: rotate(${props => props.expanded && '180deg'});
  }
`;

const SortProps = styled.div`
  display: grid;
  column-gap: var(--space);
  grid-auto-flow: column;
  justify-content: start;
  opacity: ${props => (props.expanded ? 1 : 0)};
`;

const SortProp = styled.div`
  --content-color: ${props => (props.active ? 'var(--color-grey)' : 'var(--color-text)')};

  transition: color var(--motion) ease-in-out;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.75rem;
  justify-content: start;
  align-items: center;
  color: var(--content-color);

  ${mq.medium} {
    row-gap: 1rem;
  }

  ${linkHover};

  svg {
    transition: opacity var(--motion);
    opacity: ${props => (props.active ? 1 : 0)};
    transform: rotate(${props => props.descending && '180deg'});
  }
`;

export default Sort;
